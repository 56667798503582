<template>
  <div>
    <div class="header">
      <div class="header_box">
        <div class="num1">{{ school_num }}</div>
        <div>使用学校点数</div>
      </div>
      <div class="header_box">
        <div class="num2">{{ teacher_num }}</div>
        <div>名师数量</div>
      </div>
      <div class="header_box">
        <div class="num3">{{ resource_num }}</div>
        <div>资源数量</div>
      </div>
    </div>
    <div class="line">
      <div class="line_lt">
        <div class="line_tt">本周名师预约数量</div>
        <div class="line_box" ref="line"></div>
      </div>
      <div class="line_rt">
        <div class="line_tt">一周名师预约表</div>
        <div class="table_box">
          <table border="1" cellpadding="13" cellspacing="0">
            <tr>
              <th v-for="(item, i) in infoList" :key="i">
                <div>{{ item.date }}</div>
                <div>周{{ item.week }}</div>
              </th>
            </tr>
            <tr v-for="(item, i) in infoList" :key="i">
              <td v-for="(it, i1) in item.appointment" :key="i1">
                <div>名师名字：{{ it.famous_name }}</div>
                <div>学校：{{ it.school_name }}</div>
                <div>时间段：{{ it.date }}</div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  data() {
    return {
      school_num: '',
      teacher_num: '',
      resource_num: '',
      famousappointnum: [],
      famousadate: [],
      infoList: [],
    }
  },
  mounted() {
    this.schoolnum()
    this.famousappointinfo()
    this.init()
  },
  methods: {
    init() {
      this.chartDom = this.$refs.line
      this.myChart = echarts.init(this.chartDom)
      this.getfamousappointnum()
    },

    // 学校数量 /edulogin.php/statistics/schoolnum
    async schoolnum() {
      const { data: res } = await this.$http.get(
        'edulogin.php/statistics/schoolnum'
      )
      if (res.code != 1) return this.$message.error(res.msg)
      this.school_num = res.data.school_num
      this.teacher_num = res.data.teacher_num
      this.resource_num = res.data.resource_num
    },
    // 一周名师预约表 /edulogin.php/statistics/famousappointinfo
    async famousappointinfo() {
      const { data: res } = await this.$http.get(
        'edulogin.php/statistics/famousappointinfo'
      )
      if (res.code != 1) return this.$message.error(res.msg)
      this.infoList = res.data
    },
    // 一周预约数量 /edulogin.php/statistics/getfamousappointnum
    async getfamousappointnum() {
      const { data: res } = await this.$http.get(
        'edulogin.php/statistics/getfamousappointnum'
      )
      if (res.code != 1) return this.$message.error(res.msg)
      res.data.forEach((e) => {
        this.famousappointnum.push(+e.num)
        this.famousadate.push(e.date)
        console.log(3, this.famousappointnum)
        console.log(5, this.famousadate)
      })
      this.setOption()
    },
    setOption() {
      this.option = {
        xAxis: {
          type: 'category',
          data: this.famousadate,
          axisLabel: {
            rotate: 20,
          },
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: this.famousappointnum,
            type: 'bar',
            color: [
              '#5470c6',
              '#91cc75',
              '#fac858',
              '#ee6666',
              '#73c0de',
              '#3ba272',
              '#fc8452',
              '#9a60b4',
              '#ea7ccc',
            ],
          },
        ],
      }

      this.option && this.myChart.setOption(this.option)
    },
  },
}
</script>

<style lang="less" scoped>
.header {
  // width: 1035px;
  width: 100%;
  height: 127px;
  background: #f1f7fe;
  border: 1px solid #538eff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  .header_box {
    width: 33.33%;
    height: 76px;
    border-right: 1px solid #cfe0f5;
    text-align: center;
    color: #535454;
    font-size: 22px !important;
    .num1 {
      font-size: 34px !important;
      color: #f8cb00;
    }
    .num2 {
      font-size: 34px !important;
      color: #26b99a;
    }
    .num3 {
      font-size: 34px !important;
      color: #f7484d;
    }
  }
  .header_box:nth-child(3) {
    border-right: none;
  }
}
.line {
  width: 100%;
  display: flex;
  .line_lt {
    width: 470px;
    // height: 395px;
    border: 1px solid #edeff0;
    border-radius: 4px;
    margin-right: 30px;
    .line_tt {
      width: 100%;
      height: 75px;
      border-bottom: 1px solid #edeff0;
      line-height: 75px;
      font-size: 26px;
      color: #525252;
      text-align: center;
    }
    .line_box {
      width: 470px;
      height: 470px;
      // background-color: pink;
    }
  }
  .line_rt {
    flex: 1;
    // height: 395px;
    border: 1px solid #edeff0;
    border-radius: 4px;
    .line_tt {
      width: 100%;
      height: 75px;
      border-bottom: 1px solid #edeff0;
      line-height: 75px;
      font-size: 26px;
      color: #525252;
      text-align: center;
    }
    .table_box {
      width: 100%;
      max-height: 570px;
      overflow-y: auto;
    }
    table {
      width: 100%;
    }
  }
}
</style>